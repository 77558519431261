<template>
    <span @click.stop="clickHandel">
        <slot></slot>
        <span @click.stop="">
            <el-dialog
                class="maintenance-person-detail-modal"
                title="运维人员详情"
                :visible.sync="dialogVisible"
                width="1030px"
            >
                <div class="maintenance-person-modal-detail-content">
                    <div :id="`personMap_${id}`" class="top-map-container">
                        <div class="last-time">
                            <span class="title">最后上报时间</span>
                            <span class="time">{{jobAcceptTime}}</span>
                        </div>
                    </div>
                    <div class="bottom-person-info-container">
                        <div class="left-info">
                            <div class="title">
                                基本信息
                            </div>
                            <div class="info-detail-container">
                                <div
                                    class="info-item"
                                    v-for="(item, index) in detailInfo"
                                    :key="index"
                                >
                                    <div class="info-name">{{item.name}}：</div>
                                    <div class="info-value">{{item.value}}</div>
                                </div>
                            </div>
                        </div>
                        <div class="right-info">
                            <div class="title">
                                运维信息
                            </div>
                            <div class="right-info-detail-container">
                                <div class="work-info-container">
                                    <div class="work-total">工单总量：{{ jobStatistics.jobCount }}</div>
                                    <div class="work-maintence">救援工单：{{ jobStatistics.inspectionJobCount }}</div>
                                    <div class="work-xunjian">巡检工单：{{ jobStatistics.rescueJobCount }}</div>
                                </div>
                                <div class="work-detail-container">
                                    <div class="work-detail-item" v-for="(item, index) in orderList" :key="index">
                                        <div class="work-info-item" style="width: 100%">
                                            <span>运维工单编号：</span>
                                            <span>{{item.gdbh}}</span>
                                        </div>
                                        <div class="work-info-item">
                                            <span>故障名称：</span>
                                            <span>{{item.gzmc}}</span>
                                        </div>
                                        <div class="work-info-item">
                                            <span>故障信息：</span>
                                            <span>{{item.gzxx}}</span>
                                        </div>
                                        <div class="work-info-item">
                                            <span>接单时间：</span>
                                            <span>{{item.jdsj}}</span>
                                        </div>
                                        <div class="work-info-item">
                                            <span>处理记录：</span>
                                            <span>{{item.cljl}}</span>
                                        </div>
                                        <div class="work-info-item">
                                            <span>处理结果：</span>
                                            <span>{{item.cljg}}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </el-dialog>
        </span>
    </span>
</template>

<script>
import L from "leaflet/dist/leaflet";
import "leaflet.chinatmsproviders";
import "leaflet/dist/leaflet.css";
import './DetailModal.less';
import axios from '@/utils/axios'
import personIconImg from '@/assets/img/main-person-icon.png';

const personIcon = L.icon({
    iconUrl: personIconImg
});

export default {
    name: 'DetailModal',
    props: {
        id: {
            type: Number,
            default: -1
        }
    },
    data() {
        return {
            dialogVisible: false,
            map: null,
            detailInfo: [
                { name: '姓名', value: '' },
                { name: '性别', value: '' },
                { name: '身份证号', value: '' },
                { name: '联系方式', value: '' },
                { name: '归属站点', value: '' },
                { name: '账号类型', value: '' },
                { name: '账号有效期', value: '' },
                { name: '当前状态', value: '' }
            ],
            jobStatistics: {
                inspectionJobCount: 0,
                jobCount: 0,
                rescueJobCount: 0
            },
            orderList: [],
            jobAcceptTime: '',
            point: null
        }
    },
    methods: {
        clickHandel() {
            this.dialogVisible = true;
            this.$nextTick(() => {
                this.initMap();
                setTimeout(() => {
                    this.getDetailInfo();
                    this.getSimpleJobInfo();
                }, 500);
            });
        },
        initMap() {
            if (this.map) {
                return;
            }
            const normalm = L.tileLayer.chinaProvider("GaoDe.Normal.Map", {
                maxZoom: 18,
                minZoom: 1,
            });
            const normal = L.layerGroup([normalm]);
            const map = L.map(`personMap_${this.id}`, {
                center: [40.125721, 116.320473],
                zoom: 10,
                layers: [normal],
                zoomControl: false,
            });
            this.map = map;
        },
        addIcon(lat, lng) {
            if (this.map) {
                if (this.point) {
                    this.point.remove();
                }
                this.point = L.marker([lat, lng], {
                    icon: personIcon
                });
                this.point.addTo(this.map);
                this.map.panTo([lat, lng]);
            }
        },
        getDetailInfo() {
            const url = `/api/operationPerson/getOperationPersonDetail/${this.id}`;
            axios.get(url).then(rs => {
                const {
                    userName,
                    sex,
                    idCardNum,
                    mobile,
                    siteName,
                    accountType,
                    accountEndValidDate,
                    status,
                    jobStatistics
                } = rs;
                this.detailInfo = [
                    { name: '姓名', value: userName },
                    { name: '性别', value: sex === 1 ? '男' : '女' },
                    { name: '身份证号', value: idCardNum },
                    { name: '联系方式', value: mobile },
                    { name: '归属站点', value: siteName },
                    { name: '账号类型', value:  accountType === 1 ? '永久' : '临时' },
                    { name: '账号有效期', value: accountEndValidDate },
                    { name: '当前状态', value: status === 1 ? '启用' : '停用' },
                ];
                this.jobStatistics = jobStatistics;
            }).catch(err => {
                this.$message.error(err);
            });
        },
        async getSimpleJobInfo() {
            try {
                const order =  await axios.get(`/api/job/getLastJob/${this.id}`);
                if (order && order.jobCode) {
                    const rs = await axios.post('/api/job/getSimpleJobInfo', { jobCode: order.jobCode });
                    const jobInfo = await axios.post('/api/rescueJob/getJobList001', { operatorId: this.id });
                    this.orderList = jobInfo;
                    const { jobAcceptLat, jobAcceptLng, jobAcceptTime } = rs;
                    if (jobAcceptLat && jobAcceptLng) {
                        //  gps有时 填充地图
                        this.addIcon(jobAcceptLat, jobAcceptLng);
                    }
                    this.jobAcceptTime = jobAcceptTime;
                }
            } catch (error) {
                this.$message.error(error);
            }
        }
    }
}
</script>