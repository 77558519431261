<template>
    <div class="table-tool-container">
        <div class="table-search-tool-container">
            <el-form class="maintenance-person-form" :inline="true" :model="formData">
                <el-form-item label="运维人员">
                    <el-input placeholder="运维人员" size="small" v-model="formData.keyWord"></el-input>
                </el-form-item>
                <el-form-item label="归属">
                    <el-tree-select 
                        v-model="formData.orgId"
                        size="small"
                        ref="group-select-tree"
                        class="group-select-tree" 
                        :selectParams="selectParams" 
                        :treeParams="treeParams" 
                        style="width: 200px;"
                    >
                    </el-tree-select>
                </el-form-item>
                <el-form-item class="account-type-form-item" label="账号类型">
                    <el-select v-model="formData.accountType" placeholder="账号类型" size="small">
                        <el-option label="全部" :value="''"></el-option>
                        <el-option label="永久" :value="1"></el-option>
                        <el-option label="临时" :value="2"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item class="account-status-form-item" label="账号状态">
                    <el-select v-model="formData.status" placeholder="账号状态" size="small">
                        <el-option label="全部" :value="''"></el-option>
                        <el-option label="启用" :value="1"></el-option>
                        <el-option label="停用" :value="2"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item style="float: right; marginRight: 0px;">
                    <el-button type="primary" @click="getTableData">搜索</el-button>
                    <el-button @click="resetForm">重置</el-button>
                </el-form-item>
            </el-form>
        </div>
        <div class="table-operate-tool-container">
            <OperateModal :operateType="'add'" :getTableData="getTableData">
                <el-button type="primary" style="marginRight: 10px;">新建</el-button>
            </OperateModal>
            <!-- <el-button type="primary">导出</el-button>
            <el-button type="primary">导入</el-button> -->
        </div>
    </div>
</template>

<script>
import OperateModal from './OperateModal';
import './TableTool.less';
import axios from '@/utils/axios';
export default {
    name: 'Table',
    components: {
        OperateModal
    },
    props: {
        page: {
            type: Number,
            default: 1
        },
        pageSize: {
            type: Number,
            default: 10
        }
    },
    data() {
        return {
            formData: {
                keyWord: '',
                orgId: '',
                accountType: '',
                status: ''
            },
            selectParams: {
                multiple: false,
                clearable: true,
                placeholder: '请选择机构归属'
            },
            treeParams: {
                clickParent: true,
                filterable: false,
                'check-strictly': true,
                'default-expand-all': true,
                'expand-on-click-node': true,
                data: [],
                props: {
                    children: 'childs',
                    label: 'orgName',
                    value: 'orgId'
                }
            }
        }
    },
    methods: {
        async getGroupTreeData() {
            const rs = await axios.get('/api/userInfo/getCurrLoginUserInfo');
            const { orgId } = rs.org;
            const groupTreeData = await axios.get(`/api/org/getOrgTree/${orgId}`);
            this.treeParams.data = [groupTreeData];
            this.$refs['group-select-tree'].treeDataUpdateFun([groupTreeData]);
        },
        async getTableData() {
            const {
                page,
                pageSize,
                formData
            } = this;
            const rs = await axios.get(`/api/operationPerson/pageList/${page}/${pageSize}`, formData);
            this.$emit('tableDataChange', rs);
        },
        resetForm() {
            this.formData = {
                keyWord: '',
                orgId: '',
                accountType: '',
                status: ''
            };
        }
    },
    async mounted() {
        this.getGroupTreeData();
        this.getTableData();
    }
}
</script>