<template>
	<span @click.stop="clickHandel">
		<slot></slot>
		<span @click.stop="">
			<el-dialog class="neweldialog" title="提示" :visible.sync="dialogVisible" width="337px">
				<div class="ft14 c3333 mb10">确认{{state === 'open' ? '启用' : '停用' }}该用户吗？</div>
				<el-input v-model="formData.stopAccountReason" type="textarea" :rows="5" maxlength="50" show-word-limit placeholder="请填写原因">
				</el-input>
				<span slot="footer" class="dialog-footer">
					<el-button @click="dialogVisible = false;">取 消</el-button>
					<el-button type="primary" @click="sureHandel" :loading="loading">确 定</el-button>
				</span>
			</el-dialog>
		</span>
	</span>
</template>

<script>
	import './ChangeState.less';
	import axios from '@/utils/axios';
	export default {
		name: 'ChangeState',
		props: {
			state: {
				type: String,
				default:'open'
			},
			getDataR: {
				type: Function,
				default: () => {}
			},
			id: {
				type: Number,
				default: -1
			},
		},
		data() {
			return {
				loading: false,
				dialogVisible: false,
				formData: {
					stopAccountReason: ''
				}
			}
		},
		methods: {
			clickHandel() {
				this.dialogVisible = true;
			},
			sureHandel() {
				this.loading = true;
				axios.post('/api/operationPerson/editStatus', {
					id: this.id, 
					status: this.state === 'open' ? 1 : 2, 
					stopAccountReason: this.formData.stopAccountReason
				 }).then(() => {
					this.$message({
						message: '操作成功',
						type: 'success'
					});
					this.loading = false;
					this.getDataR();
					this.dialogVisible = false;
					this.formData.stopAccountReason = '';
				}).catch(err => {
					this.loading = false;
					this.$message.error(err);
				});
			}
		}
	}
</script>

<style>
</style>
