<template>
    <span @click.stop="clickHandel">
        <slot></slot>
        <span @click.stop="">
            <el-dialog
                class="maintenance-person-changestatus-modal"
                title="提示"
                :visible.sync="dialogVisible"
                width="438px"
            >
                <div class="change-status-content">
                    <span style="display: block; marginBottom: 10px;">确定停用该用户吗？</span>
                    <el-input
                        type="textarea"
                        :autosize="{ minRows: 5, maxRows: 5}"
                        placeholder="请输入原因"
                    >
                    </el-input>
                </div>
                <span slot="footer" class="dialog-footer">
                    <el-button @click="dialogVisible = false;">取 消</el-button>
                    <el-button type="primary">确 定</el-button>
                </span>
            </el-dialog>
        </span>
    </span>
</template>

<script>
import './ChangeStatusModal.less';
export default {
    name: 'ChangeStatusModal',
    props: {
    },
    data() {
        return {
            dialogVisible: false
        }
    },
    methods: {
        clickHandel() {
            this.dialogVisible = true;
        },
    }
}
</script>