<template>
    <span @click.stop="clickHandel">
        <slot></slot>
        <span @click.stop="">
            <el-dialog
                class="maintenance-person-modal"
                :title="operateType === 'add' ? '新建运维人员' : '编辑运维人员'"
                :visible.sync="dialogVisible"
                width="550px"
            >
                <div class="maintenance-person-modal-content">
                    <el-form 
                        ref="operateForm" 
                        label-width="80px" 
                        class="operate-form"
                        :model="formData"
                        :rules="rules"
                    >
                        <el-form-item label="姓名" prop="userName">
                            <el-input v-model="formData.userName"></el-input>
                        </el-form-item>
                        <el-form-item label="性别">
                            <el-select placeholder="请选择" v-model="formData.sex">
                                <el-option
                                    label="男"
                                    :value="1">
                                </el-option>
                                <el-option
                                    label="女"
                                    :value="2">
                                </el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="身份证号" prop="idCardNum">
                            <el-input v-model="formData.idCardNum"></el-input>
                        </el-form-item>
                        <el-form-item label="手机号码" prop="mobile">
                            <el-input v-model="formData.mobile"></el-input>
                        </el-form-item>
                        <el-form-item label="归属班组" prop="gangerGroupId">
                            <el-select placeholder="请选择" v-model="formData.gangerGroupId">
                                <el-option
                                    v-for="item in gangerGroupList"
                                    :key="item.id"
                                    :label="item.label"
                                    :value="item.id">
                                </el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="账号类型">
                            <el-select placeholder="请选择" v-model="formData.accountType">
                                <el-option label="永久" :value="1"></el-option>
                                <el-option label="临时" :value="2"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="有效期" v-show="formData.accountType === 2">
                            <el-date-picker
                                type="datetimerange"
                                range-separator="至"
                                start-placeholder="开始日期"
                                end-placeholder="结束日期"
                                v-model="formData.validDate"
                            >
                            </el-date-picker>
                        </el-form-item>
                    </el-form>
                </div>
                <span slot="footer" class="dialog-footer">
                    <el-button @click="dialogVisible = false;">取 消</el-button>
                    <el-button type="primary" @click="submitHandel">确 定</el-button>
                </span>
            </el-dialog>
        </span>
    </span>
</template>

<script>
import axios from '@/utils/axios';
import './OperateModal.less';
import moment from 'moment';
export default {
    name: 'OperateModal',
    props: {
        operateType: {
            type: String,
            default: 'add'
        },
        getTableData: {
            type: Function,
            default: () => {}
        },
        fillData: {
            type: Object,
            default: () => ({})
        }
    },
    data() {
        return {
            dialogVisible: false,
            formData: {
                userName: '',
                sex: 1,
                idCardNum: '',
                mobile: '',
                gangerGroupId: '',
                accountType: 1,
                validDate: ''
            },
            gangerGroupList: [],
            rules: {
                userName: [
                    { required: true, message: '请输入名称', trigger: 'blur' },
                ],
                idCardNum: [
                    { required: true, message: '请输入身份证号', trigger: 'blur' },
                ],
                mobile: [
                    { required: true, message: '请输入联系方式', trigger: 'blur' },
                     { validator: this.validatePhoneNumber, trigger: 'blur' }
                ],
                gangerGroupId: [
                    { required: true, message: '请选择归属班组', trigger: 'blur' },
                ],
                validDate: [
                    { required: true, message: '请选择有效期范围', trigger: 'blur' },
                ]
            }
        }
    },
    methods: {
        clickHandel() {
            this.dialogVisible = true;
            if (this.operateType === 'edit') {
                this.formData = this.fillData;
                const { accountBeginValidDate, accountEndValidDate } = this.fillData;
                if (accountBeginValidDate && accountEndValidDate) {
                    const date1 = moment(accountBeginValidDate);
                    const date2 = moment(accountEndValidDate);
                    this.formData.validDate = [date1, date2];
                }
            }
            this.getGangerGroupList()
        },
        async getGangerGroupList() {
            const rs = await axios.get('/api/gangerGroup/getList')
            this.gangerGroupList = rs.map(item => ({ label: item.name, id: item.code }));
        },
        validatePhoneNumber(rule, value, callback) {
            if (/^1[3|4|5|7|8][0-9]{9}$/.test(value)) {
                callback();
            } else {
                callback(new Error('请输入合法的手机号'));
            }
        },
        validateMail(rule, value, callback) {
            const reg = /^[A-Za-z0-9]+([_\\.][A-Za-z0-9]+)*@([A-Za-z0-9\\-]+\.)+[A-Za-z]{2,6}$/;
            if (reg.test(value)) {
                callback();
            } else {
                callback(new Error('请输入合法的邮箱地址'));
            }
        },
        submitHandel() {
            const {
                userName,
                sex,
                idCardNum,
                mobile,
                gangerGroupId,
                accountType,
                validDate,
            } = this.formData;
            let accountBeginValidDate = '';
            let accountEndValidDate = '';
            let url = '/api/operationPerson/create';
            if (accountType === 2) {
                const [date1, date2] = validDate;
                accountBeginValidDate = moment(date1).format('YYYY-MM-DD HH:mm:ss');
                accountEndValidDate = moment(date2).format('YYYY-MM-DD HH:mm:ss');
            }
            const params = {
                userName,
                sex,
                idCardNum,
                mobile,
                gangerGroupId,
                accountType,
                accountBeginValidDate,
                accountEndValidDate
            };
            if (this.operateType === 'edit') {
                url = '/api/operationPerson/edit';
                params.id = this.fillData.id;
            }
            this.$refs.operateForm.validate(valid => {
                if (valid) {
                    axios.post(url, params).then(rs => {
                        this.$message.success('操作成功');
                        this.dialogVisible = false;
                        this.getTableData();
                    }).catch(err => {
                        this.$message.error(err);
                    })
                }
            });
        }
    }
}
</script>