<template>
    <div class="maintenance-person-management-container">
        <div class="table-tool">
            <TableTool ref=tableTool :page="page" :pageSize="pageSize" @tableDataChange="tableDataChangeHandel"/>
        </div>
        <div class="table-container">
            <div class="table-data-info">
                <img class="tips-icon" :src="require('@/assets/img/volume.png')"/>
                <span>运维人员总数</span><span style="margin: 0 10px; color: #FF0000;">{{total}},</span>
                <span>本机构运维人员总数</span><span style="margin: 0 10px; color: #FF0000;">{{total}}</span>
            </div>
            <div class="table-content">
                <el-table 
                    style="width: 100%"
                    height="100%"
                    :data="list"
                >
                    <el-table-column
                        prop="userName"
                        label="人员姓名"
                    >
                    </el-table-column>
                    <el-table-column
                        prop="mobile"
                        label="手机号码"
                    >
                    </el-table-column>
                    <el-table-column
                        label="归属班组"
                        prop="gangerGroupName"
                    >
                    </el-table-column>
                    <el-table-column
                        label="归属站点"
                        prop="siteName"
                    >
                    </el-table-column>
                    <el-table-column
                        prop="orgName"
                        label="归属机构"
                    >
                    </el-table-column>
                    <el-table-column
                        prop="createTime"
                        label="创建时间"
                        width="180"
                    >
                    </el-table-column>
                    <el-table-column
                        prop="accountType"
                        label="账号类型"
                    >
                        <template slot-scope="scope">
                            {{ scope.row.accountType === 1 ? '永久' : '临时' }}
                        </template>
                    </el-table-column>
                    <el-table-column
                        label="最后工单时间"
                        prop="jobLastTime"
                        width="180px"
                    >
                    </el-table-column>
                    <el-table-column
                        prop="status"
                        label="账号状态"
                    >
                        <template slot-scope="scope">
                            {{ scope.row.status === 1 ? '启用' : '停用' }}
                        </template>
                    </el-table-column>
                    <el-table-column
                        label="操作"
                        width="230"
                    >
                        <template slot-scope="scope">
                            <DetailModal :id="scope.row.id">
                                <a class="operate-a">查看</a>
                            </DetailModal>
                            <OperateModal 
                                :operateType="'edit'" 
                                :fillData="JSON.parse(JSON.stringify(scope.row))"
                                :getTableData="getTableData"
                            >
                                <a class="operate-a">编辑</a>
                            </OperateModal>
                            <ChangeState v-if="scope.row.status === 1" state="close" :getDataR="getTableData" :id="scope.row.id">
                                <a class="operate-a">停用</a>
                            </ChangeState>
                            <ChangeState v-if="scope.row.status === 2"  state="open" :getDataR="getTableData" :id="scope.row.id">
                                <a class="operate-a">启用</a>
                            </ChangeState>
                            <a class="operate-a" @click="deleteHandel(scope.row.id)">删除</a>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <div class="table-page">
                <div class="table-page-container">
                    <el-pagination
                        background
                        :current-page="page"
                        :page-sizes="[10, 20, 30, 40]"
                        :page-size="pageSize"
                        layout="total, sizes, prev, pager, next, jumper"
                        :total="total"
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange"
                    >
                    </el-pagination>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import TableTool from './components/TableTool';
import DetailModal from './components/DetailModal';
import ChangeStatusModal from './components/ChangeStatusModal';
import OperateModal from './components/OperateModal';
import './Index.less';
import axios from '@/utils/axios';
import ChangeState from './components/ChangeState';
export default {
    name: 'MaintenancePersonManagement',
    components: {
        TableTool,
        DetailModal,
        ChangeStatusModal,
        OperateModal,
        ChangeState
    },
    data() {
        return {
            page: 1,
            pageSize: 10,
            list: [],
            total: 0
        }
    },
    methods: {
        tableDataChangeHandel(rs) {
            const { records, total } = rs;
            this.total = total;
            this.list = records;
        },
        handleSizeChange(val) {
            this.pageSize = val;
            this.$nextTick(() => {
                this.$refs.tableTool.getTableData();
            });
        },
        handleCurrentChange(val) {
            this.page = val;
            this.$nextTick(() => {
                this.$refs.tableTool.getTableData();
            });
        },
        getTableData() {
            this.$refs.tableTool.getTableData();
        },
        deleteHandel(id) {
            this.$confirm('确认删除此人员, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                axios.post(`/api/operationPerson/remove/${id}`).then(
                    () => {
                        this.$message.success('删除成功');
                        this.getTableData();
                    }
                ).catch(err => {
                    this.$message.error(err);
                });
            });
        },
    }
}
</script>